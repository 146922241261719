import React from 'react'
import { graphql } from 'gatsby'

import { Layout, Seo } from '../components/commons'
import { RallyHeader } from '../components/rally'
import Section from '../components/sections'
import { Wrapper } from '../atoms'

const RallyContentPage = ({ data }) => {
  const { datoCmsRallyPage } = data

  return (
    <Layout>
      <Seo content={datoCmsRallyPage.seo} />
      <Wrapper>
        <RallyHeader rally={datoCmsRallyPage.rally}/>
        {datoCmsRallyPage.sections.map(section => <Section key={section.id} sectionId={section?.model?.apiKey} content={section} />)}
        {datoCmsRallyPage.rally.incomingEdition && (
          <Section sectionId={'incoming_edition'} content={datoCmsRallyPage.rally} />
        )}
      </Wrapper>
    </Layout>
  )
}

export default RallyContentPage

export const query = graphql`
  query RallyPage($slug: String!, $rallySlug: String!) {
    datoCmsRallyPage(slug: {eq: $slug}, rally: {slug: {eq: $rallySlug}}) {
      seo {
        description
        title
        twitterCard
        image {
          url
        }
      }
      name
      slug
      rally {
        ...rallyFragment
      }
      sections {
        ...sectionContentImageFragment
        ...sectionContentOnlyFragment
        ...sectionContentIconItemFragment
        ...sectionContactFragment
        ...sectionEditionsListFragment
      }
    }
  }
`
